<script>
    import BgUploadModal from '@/components/BgUploadModal'
    import BgRelationModal from '@/components/BgRelationModal'

    export default {
        components: {
            BgUploadModal: BgUploadModal,
            BgRelationModal: BgRelationModal,
        },

        props: {
            filter: {
                type: Object,
                default() {
                    return {}
                },
            },
            groupEdit: {
                type: Boolean,
                default: true,
            },
            locale: {
                type: String,
            },
            groupBy: {
                type: String,
            },
            update: {
                type: String,
            },
            groupData: {
                type: String,
            },
            collectionType: {
                type: [String, Number],
            },
            setCurrentFolder: {
                type: String,
                default: '',
            },
        },

        data() {
            return {
                fetching: true,
                folders: [],
                file: [],
                editFile: false,
                editRelation: false,
                searchValue: '',
                currentFolder: null,
                cachePagination: null,
                hasFilesWithoutFolder: false,
            }
        },

        methods: {
            /**
             * Returns the finally computed filter.
             *
             * @param  {number}  folderId
             * @return {stirng}
             */
            finalFilter(folderId) {
                let filterObject = JSON.parse(JSON.stringify(this.filter))
                filterObject['FolderId'] = folderId
                return filterObject
            },

            /**
             * Returns whether the given dataset is an image.
             *
             * @param  {object}  data
             * @return {boolean}
             */
            isImage(data) {
                return 'Extension' in data && (data.Extension.toLowerCase() === 'jpg' || data.Extension.toLowerCase() === 'png' || data.Extension.toLowerCase() === 'gif' || data.Extension.toLowerCase() === 'jpeg')
            },

            /**
             * Changes the selected tab.
             *
             * @param  {number}  folderId
             */
            changeTab(folderId) {
                this.currentFolder = folderId
                this.$route.params.folder = folderId
            },

            /**
             * Returns the translation.
             *
             * @param  {object}  data
             * @param  {string}  key
             * @return {string}
             */
            translation(data, key) {
                if ('FileI18ns' in data.row) {
                    let result = this.$root.translation(data.row.FileI18ns, {})

                    if (typeof result === 'object' && Object.keys(result).length <= 0) {
                        result = this.$root.translation(data.row.FileI18ns, {}, 'zz_ZZ')
                    }

                    if (typeof result === 'object' && Object.keys(result).length > 0) {
                        if (key in result) {
                            return result[key]
                        }
                    }
                }

                return '—'
            },

            /**
             * Returns the icon String.
             *
             * @param  {object}  data
             * @return {string}
             */
            folderTypeImage(folder) {
                let icon = '';
                if (folder.CollectionTypeId === 1) {
                    icon = 'layers'
                } else if (folder.CollectionTypeId === 2) {
                    icon = 'tag'
                } else if (folder.CollectionTypeId === 3) {
                    icon = 'rhombus-split'
                } else if (folder.CollectionTypeId === 4) {
                    icon = 'chip'
                } else if (folder.CollectionTypeId === 5) {
                    icon = 'web'
                }
                return '<span class="icon"><i class="mdi mdi-' + icon + ' is-medium"></i></span>'
            },

            /**
             * Returns the translation.
             *
             * @param  {object}  data
             * @return {string}
             */
            displayLocale(data) {
                let displayName = '—'

                if ('FileI18ns' in data.row) {
                    let result = this.$root.translation(data.row.FileI18ns, {})

                    if (typeof result === 'object' && Object.keys(result).length <= 0) {
                        result = this.$root.translation(data.row.FileI18ns, {}, 'zz_ZZ')
                    }

                    if (typeof result === 'object' && Object.keys(result).length > 0) {
                        if (result.Locale === 'zz_ZZ') {
                            displayName = this.$t('International')
                        } else {
                            this.$root.countries.forEach(country => {
                                country.Languages.forEach(language => {
                                    if (language.LocaleCode === result.Locale) {
                                        displayName = this.$root.translation(language.LanguageI18ns, null, 'de_AT').Name
                                    }
                                })
                            })
                        }
                    }
                }

                return displayName
            },

            /**
             * Returns the translation.
             *
             * @param  {object}  data
             * @return {string}
             */
            displaySort(data) {
                let displayName = '-'

                if ('FileI18ns' in data.row) {
                    let result = this.$root.translation(data.row.FileI18ns, {})
                    if (typeof result === 'object' && Object.keys(result).length <= 0) {
                        result = this.$root.translation(data.row.FileI18ns, {}, 'zz_ZZ')
                    }
                    if (typeof result === 'object' && Object.keys(result).length > 0) {
                        displayName = result.Sort ? result.Sort : '0'
                    }
                }

                return displayName
            },

            isInternational(data) {
                let international = this.$t('No')
                if ('FileI18ns' in data.row) {
                    data.row.FileI18ns.forEach(fileI18n => {
                        if (fileI18n.Locale === 'zz_ZZ') {
                            international = this.$t('Yes')
                            return false;
                        }
                    })
                }

                return international
            },

            /**
             * Returns the translation.
             *
             * @param  {object}  data
             * @return {string}
             */
            contentLocale(data) {
                let displayName = '—'

                if ('ContentLocale' in data.row) {
                    this.$root.countries.forEach(country => {
                        country.Languages.forEach(language => {
                            if (language.LocaleCode === data.row.ContentLocale) {
                                displayName = this.$root.translation(language.LanguageI18ns, null, 'de_AT').Name
                            }
                        })
                    })
                }

                return displayName
            },

            viewEdit(data) {
                this.file = [
                    {
                        completed: false,
                        response: data.row
                    }
                ]

                this.editFile = true
            },

            viewRelation(data) {
                this.file = [
                    {
                        completed: false,
                        response: data.row
                    }
                ]

                this.editRelation = true
            },

            searchFor(temp) {
                this.searchValue = temp
                this.editFile = false
            },

            done() {
                this.editFile = false
                if (typeof this.$route.params.for !== 'undefined') {
                    this.$route.params.source.pop()
                    this.$router.push({
                        name: 're-details',
                        params: {
                            for: this.$route.params.for,
                            id: this.$route.params.id,
                            folder: this.$route.params.folder,
                            source: this.$route.params.source,
                        }
                    })
                } else {
                    this.$router.push(this.update + '?p=' + this.cachePagination.current + '&c=' + this.cachePagination.perPage + '&f=' + this.currentFolder)
                }
            },

            paginated(pagination) {
                this.cachePagination = pagination
            }
        },

        created() {
            let params = {count: -1}
            if (typeof this.collectionType !== 'undefined') {
                params['filter[CollectionTypeId]'] = this.collectionType
            }

            this.$root.fetch('folder', params).then(response => {
                this.folders = response.Folder
                if (this.$router.history.current.hash) {
                    this.$route.params.folder = this.$router.history.current.hash.replace('#', '')
                    window.location.href = window.location.href.replace(this.$router.history.current.hash, '')
                }

                if (this.folders.length > 0) {
                    this.currentFolder = this.$route.params.folder > 0 ? this.$route.params.folder : this.folders[0].Id
                } else {
                    this.currentFolder = false
                }

                this.hasFilesWithoutFolder = response.hasFilesWithoutFolder
                if (this.setCurrentFolder > 0) {
                    this.currentFolder = this.setCurrentFolder
                }
            })
        },
    }
</script>

<template>
    <div>
        <b-message v-if="currentFolder === false" type="is-warning" has-icon>
            {{ $t('The media cannot be shown for this collection type, because there are no folders assigned.') }}
        </b-message>

        <nav v-if="folders.length > 0" class="tabs is-boxed" style="margin: 0 -1rem 1rem">
            <ul style="padding: 0 1rem">
                <li v-if="typeof this.collectionType === 'undefined' && hasFilesWithoutFolder" :class="{ 'is-active': currentFolder === -1 }">
                    <a @click.prevent="changeTab(-1)" class="has-text-danger">
                        <span>
                            <span class="icon">
                                <i class="mdi mdi-alert is-medium"/>
                            </span>
                        </span>
                        <span>{{ $t('Without folder') }}</span>
                    </a>
                </li>
                <template v-for="(folder, index) in folders">
                    <li :class="{ 'is-active': currentFolder == folder.Id }" :key="index">
                        <a @click.prevent="changeTab(folder.Id)">
                            <span>
                                <span v-html="folderTypeImage(folder)" />
                                {{ $root.translation(folder.FolderI18ns, null, $root.$i18n.locale).Name ? $root.translation(folder.FolderI18ns, null, $root.$i18n.locale).Name : $root.translation(folder.FolderI18ns, null, 'de_AT').Name }}
                            </span>
                        </a>
                    </li>
                </template>
            </ul>
        </nav>

        <div v-if="folders.length > 0">
            <slot :current-folder="currentFolder"></slot>
        </div>

        <section v-if="folders.length > 0" style="margin-top: 1rem">
            <div class="tab-item" v-if="typeof this.collectionType === 'undefined' && hasFilesWithoutFolder && currentFolder === -1">
                <bg-table :remove="{ id: 'Id', render: row => row.OriginalName, desc: $t('File') }"
                          :searchValue="searchValue" path="file" object="File" :filter="finalFilter(-1)"
                          :locale="locale"
                          @paginated="paginated">
                    <template v-slot="data">
                        <bg-column :label="$t('Preview')">
                            <div class="bg-upload__row--preview">
                                <div v-if="isImage(data.row) || data.row.Extension === 'pdf'"
                                     class="bg-upload__row--preview--image">
                                    <img :src="'/uploads/thumbnails/' + data.row.Id +'/image.' + (data.row.Extension === 'pdf' ? 'jpg' : data.row.Extension) + '?v=' + (data.row.UpdatedOn).replace(/\D/g, '')"
                                         alt="">
                                </div>
                                <p v-else>{{ $root.fileSize(data.row.Size) }}</p>
                            </div>
                        </bg-column>
                        <bg-column :label="$t('Content')" sort="ContentLocale">{{ contentLocale(data) }}{{ contentLocale(data) !== '-' ? ' (' + data.row.ContentLocale + ')' : '' }}</bg-column>
                        <bg-column :label="$t('Language')" sort="Locale">{{ displayLocale(data) }}</bg-column>
                        <bg-column :label="$t('International')">{{ displayLocale(data) }}</bg-column>
                        <bg-column :label="$t('Display name')" sort="DisplayName">
                            {{ translation(data, 'DisplayName') }}
                        </bg-column>
                        <bg-column :label="$t('Filename')" sort="FileName">
                            {{ translation(data, 'FileName') }}.{{ data.row.Extension }}
                        </bg-column>
                        <!-- <bg-column-tags :label="$t('Tags')"   sort="File2Tags"    :collection="data.row" name="Tag" /> -->
                    </template>

                    <template v-slot:tools="data">
                        <button @click.prevent="viewRelation(data)" class="button is-success" type="button"
                                style="border-radius: 50%">
                            <b-icon icon="link" custom-size="default"/>
                        </button>
                        <a :href="`/uploads/${data.row.Id}/${(data.row.OriginalName).includes('.' + data.row.Extension) ? data.row.OriginalName : data.row.OriginalName + '.' + data.row.Extension}`"
                           target="_blank" :download="`${(data.row.OriginalName).includes('.' + data.row.Extension) ? data.row.OriginalName : data.row.OriginalName + '.' + data.row.Extension}`"
                           class="button is-success" type="button" style="border-radius: 50%">
                            <b-icon icon="download" custom-size="default"/>
                        </a>
                        <button @click.prevent="viewEdit(data)" class="button is-success" type="button"
                                style="border-radius: 50%">
                            <b-icon icon="pencil" custom-size="default"/>
                        </button>
                    </template>

                    <template>
                        Gruppe !
                    </template>
                </bg-table>
            </div>

            <template v-for="(folder, index) in folders">
                <div class="tab-item" :key="index" v-if="currentFolder == folder.Id">
                    <bg-upload v-if="folder.CollectionTypeId === 5" :reload="true" :folder-id="folder.Id" collection="Neos" :collection-id="folder.CollectionTypeId.toString()" />
                    <br>
                    <bg-table :remove="{ id: 'Id', render: row => row.OriginalName, desc: $t('File') }"
                              :searchValue="searchValue" path="file" object="File" :filter="finalFilter(folder.Id)"
                              :locale="locale" @paginated="paginated">
                        <template v-slot="data">
                            <bg-column :label="$t('Preview')">
                                <div class="bg-upload__row--preview">
                                    <div v-if="isImage(data.row) || data.row.Extension === 'pdf'"
                                         class="bg-upload__row--preview--image">
                                        <img :src="'/uploads/thumbnails/' + data.row.Id +'/image.' + (data.row.Extension === 'pdf' ? 'jpg' : data.row.Extension) + '?v=' + (data.row.UpdatedOn).replace(/\D/g, '')"
                                             alt="">
                                    </div>
                                    <p v-else>{{ $root.fileSize(data.row.Size) }}</p>
                                </div>
                            </bg-column>
                            <bg-column :label="$t('Content')" sort="ContentLocale">{{ contentLocale(data) }}{{ contentLocale(data) !== '-' ? ' (' + data.row.ContentLocale + ')' : '' }}</bg-column>
                            <bg-column :label="$t('Language')" sort="Locale">
                                {{ displayLocale(data) }}
                                <div style="opacity:.5" v-if="displaySort(data) !== '-'">({{ displaySort(data) }})</div>
                            </bg-column>
                            <bg-column :label="$t('International')">{{ isInternational(data) }}</bg-column>
                            <bg-column :label="$t('Display name')" sort="DisplayName">
                                {{ translation(data, 'DisplayName') }}
                            </bg-column>
                            <bg-column :label="$t('Filename')" sort="FileName">
                                {{ translation(data, 'FileName') }}.{{ data.row.Extension }}
                            </bg-column>
                        </template>

                        <template v-slot:tools="data">
                            <button @click.prevent="viewRelation(data)" class="button is-success" type="button"
                                    style="border-radius: 50%">
                                <b-icon icon="link" custom-size="default"/>
                            </button>
                            <a :href="`/uploads/${data.row.Id}/${(data.row.OriginalName).includes('.' + data.row.Extension) ? data.row.OriginalName : data.row.OriginalName + '.' + data.row.Extension}`"
                               target="_blank" :download="`${(data.row.OriginalName).includes('.' + data.row.Extension) ? data.row.OriginalName : data.row.OriginalName + '.' + data.row.Extension}`"
                               class="button is-success" type="button" style="border-radius: 50%">
                                <b-icon icon="download" custom-size="default"/>
                            </a>
                            <button @click.prevent="viewEdit(data)" class="button is-success" type="button"
                                    style="border-radius: 50%">
                                <b-icon icon="pencil" custom-size="default"/>
                            </button>
                        </template>

                        <template>
                            Gruppe !
                        </template>
                    </bg-table>
                </div>
            </template>
        </section>

        <bg-upload-modal @close="editFile = false" @search="searchFor" @done="done" :show="editFile" :folders="folders"
                         :files="file"/>
        <bg-relation-modal @close="editRelation = false" @search="searchFor" @done="done" :show="editRelation"
                           :files="file"/>
    </div>
</template>
