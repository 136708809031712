<script>
import BgUploadModalView from '@/components/BgUploadModalView'

export default {
    components: {
        BgUploadModalView: BgUploadModalView,
    },

    props: {
        show: {
            type: Boolean,
            required: true,
        },
        files: {
            type: Array,
            required: true,
        },
        folders: {
            type: Array,
            required: true,
        },
        isUpload: {
            type: Boolean,
            required: false,
        }
    },

    data () {
        return {
            visible: false,
            selectedFile: 0,
        }
    },

    watch: {
        show: function (newValue) {
            this.visible = newValue
        },
    },

    methods: {
        saved (index) {
            const updatedFiles = [...this.files];
            updatedFiles[index].completed = true;
            let pos = undefined;

            for (let tempIndex in updatedFiles) {
                if (!updatedFiles[tempIndex].completed) {
                    pos = tempIndex;
                    break;
                }
            }

            if (typeof pos === 'undefined') {
                this.visible = false;
                this.$emit('done', updatedFiles);
            } else {
                this.selectedFile = parseInt(pos);
            }

            // Emit the updated files array to the parent component
            this.$emit('update:files', updatedFiles);
        },
    }
}
</script>

<template>
    <b-modal @close="$emit('close')" :active.sync="visible" has-modal-card custom-class="bg-upload__modal">
        <div class="card">
            <div class="card-content">
                <div class="columns bg-upload__grid" v-if="files.length > 1">
                    <div class="column bg-upload__aside">
                        <p class="is-size-5"><strong>{{ $t('Files') }}</strong></p>
                        <div class="bg-upload__content">
                            <template v-for="(file, index) in files">
                                <div :key="index" :class="{ 'bg-upload__row': true, 'bg-upload__row--selected': index === selectedFile }" @click.prevent="selectedFile = index">
                                    <div :class="{ 'bg-upload__row--preview': true, 'bg-upload__row--preview--completed': file.completed}">
                                        <div v-if="'image' in file" class="bg-upload__row--preview--image">
                                            <img :src="file.image">
                                        </div>
                                        <p v-else>{{ $root.fileSize(file.response.Size) }}</p>
                                    </div>
                                    <div style="display: flex; justify-content: center; flex-flow: column">
                                        <div>
                                            <p style="height: 1.5rem; overflow: hidden; text-overflow: ellipsis"><strong>{{ file.response.OriginalName }}</strong></p>
                                            <p v-if="file.completed">{{ $t('Completed') }}</p>
                                            <p v-if="!file.completed">{{ $t('Not completed') }}</p>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>

                    <template v-for="(file, index) in files">
                        <div class="column is-three-quarters" :key="index" v-if="index === selectedFile">
                            <bg-upload-modal-view :file="file" :folders="folders" @saved="saved(index)" :is-upload="true"/>
                        </div>
                    </template>
                </div>

                <div class="columns bg-upload__grid" v-if="files.length === 1">
                    <div class="column">
                        <bg-upload-modal-view @search="value => $emit('search', value)" :file="files[0]"
                                              :folders="folders" @saved="saved(0)" :is-upload="isUpload"/>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>
