<script>
    export default {
        props: {
            file: {
                type: Object,
                required: true,
            },
            folders: {
                type: Array,
                required: true,
            },
            isUpload: {
                type: Boolean,
                required: false,
            },
        },

        data() {
            return {
                Id: 0,
                Size: null,
                StateId: null,
                File2Tags: [],
                FolderId: null,
                Thumbnail: null,
                translations: {},
                ContentLocale: '',
                sendingData: false,
                OriginalName: null,
                Memo: '',
                translationList: '',
                translationTab: '__overview',
                computed: {
                    languages: null,
                },
                filesInSelectedFolder: [{Id: null}],
            }
        },

        watch: {
            FolderId: function (after, before) {
                if (after !== null && before !== null) {
                    this.$root.fetch('file', {'filter[FolderId]': after, 'count': '-1'}).then(response => {
                        if ('File' in response) {
                            this.filesInSelectedFolder = response.File
                        }
                    })
                }
            },
        },

        mounted() {
            this.$root.fetch('file', {
                'filter[FolderId]': this.file.response.FolderId ? this.file.response.FolderId.toString() : -1,
                'locale': this.$root.settings('locale'),
                'count': '-1'
            }).then(response => {
                this.filesInSelectedFolder = response.File
            })

            if (Array.isArray(this.file.response.File2Tags) && this.file.response.File2Tags.length > 0) {
                let renderedTags = []
                this.file.response.File2Tags.forEach(file2tag => {
                    renderedTags.push({
                        Id: file2tag.Tag.Id,
                        Name: this.$root.translation(file2tag.Tag.TagI18ns, {}, 'de_AT').Name
                    })
                })
                this.File2Tags = renderedTags
            }

            this.Id = this.file.response.Id
            this.Size = this.file.response.Size
            this.StateId = this.file.response.StateId
            this.OriginalName = this.file.response.OriginalName
            this.Memo = this.file.response.Memo
            this.ContentLocale = this.file.response.ContentLocale
            this.FolderId = this.file.response.FolderId ? this.file.response.FolderId.toString() : -1

            if ('FileI18ns' in this.file.response) {
                let translationList = []

                this.file.response.FileI18ns.forEach(translation => {
                    translationList.push(translation.Locale)
                    this.translations[translation.Locale] = {
                        AltText: translation.AltText,
                        FileName: translation.FileName,
                        Description: translation.Description,
                        DisplayName: translation.DisplayName,
                        Sort: translation.Sort,
                    }
                })

                translationList.sort()
                this.translationList = translationList.join('|')
            } else if (this.isUpload && this.$root.Cookie.get('fileUploadTranslationList')) {
                let translationList = this.$root.Cookie.get('fileUploadTranslationList').split('|')
                translationList.forEach(translation => {
                    this.translations[translation] = {
                        AltText: '',
                        FileName: '',
                        Description: '',
                        DisplayName: '',
                    }
                })

                translationList.sort()
                this.translationList = translationList.join('|')
            }

            if ('Extension' in this.file.response && (this.isImage(this.file.response) || this.file.response.Extension === 'pdf')) {
                this.Thumbnail = '/uploads/thumbnails/' + this.file.response.Id + '/image.' + (this.file.response.Extension === 'pdf' ? 'jpg' : this.file.response.Extension)
            }

            if (this.isUpload && this.$root.Cookie.get('fileUploadContentLocale')) {
                this.ContentLocale = this.$root.Cookie.get('fileUploadContentLocale')
            }
        },

        updated() {
            if (this.FolderId === null) {
                this.FolderId = this.file.response.FolderId ? this.file.response.FolderId.toString() : -1
            }
        },

        methods: {
            /**
             * Returns the GET param
             */
            param(key) {
                return (key in this.$route.params)
                    ? this.$route.params[key]
                    : null
            },

            /**
             * Returns either all languages or the LocaleCode for an ID.
             *
             * @return {array}
             */
            languages() {
                if (this.computed.languages === null) {
                    this.computed.languages = []

                    this.$root.countries.forEach(country => {
                        country.Languages.forEach(language => {
                            this.computed.languages.push({
                                country: country,
                                language: language,
                            })
                        })
                    })
                }

                return this.computed.languages
            },

            /**
             * Send all values unified to the server.
             */
            saveValues() {
                if (this.translationList.length < 4) {
                    alert('Minimum 1 language must be selected.');

                    return false;
                }
                this.sendingData = true

                let dataToPost = {}
                dataToPost.Size = this.Size
                dataToPost.StateId = this.StateId
                dataToPost.FolderId = this.FolderId
                dataToPost.Id = this.file.response.Id
                dataToPost.OriginalName = this.OriginalName
                dataToPost.Memo = this.Memo
                dataToPost.ContentLocale = this.ContentLocale

                if (this.File2Tags.length > 0) {
                    dataToPost.File2Tags = []
                    this.File2Tags.forEach(tag => dataToPost.File2Tags.push(tag.Id))
                }

                dataToPost.FileI18ns = []
                this.translationList.replace('::', '_').split('|').forEach(localeCode => {
                    let translation = JSON.parse(JSON.stringify(this.translations[localeCode]))
                    translation.Id = this.file.response.Id
                    translation.Locale = localeCode
                    if (!translation.Sort) {
                        delete translation.Sort
                    }
                    if (this.isUpload) {
                        let list = []
                        if (this.$root.Cookie.get('fileUploadTranslationList')) {
                            list = (this.$root.Cookie.get('fileUploadTranslationList')).split('|');
                        }
                        if (!list.includes(localeCode)) {
                            list.push(localeCode);
                        }
                        this.$root.Cookie.set('fileUploadTranslationList', list.join('|'))
                    }
                    dataToPost.FileI18ns.push(translation)
                })

                if (this.isUpload) {
                    this.$root.Cookie.set('fileUploadContentLocale', dataToPost.ContentLocale)
                }

                this.$root.post('/file/' + this.file.response.Id, dataToPost).then(response => {
                    this.sendingData = false
                    if ('File' in response) {
                        this.$emit('saved')
                    }
                })
            },

            /**
             * Toggles the available languages.
             */
            toggleLanguage(localeCode, changeToIfFound) {
                let translationList = []
                if (this.translationList.length > 0) {
                    translationList = this.translationList.split('|')
                }

                if (this.translationList.replace('::', '_').includes(localeCode)) {
                    if (typeof changeToIfFound === 'undefined') {
                        translationList.splice(translationList.indexOf(localeCode), 1)
                    } else {
                        translationList[translationList.findIndex(locale => locale === localeCode)] = changeToIfFound
                    }
                } else if (typeof changeToIfFound === 'undefined') {
                    translationList.push(localeCode)
                    if (!(localeCode in this.translations)) {
                        let onlyFileName = this.OriginalName.split('.')
                        onlyFileName.pop();
                        onlyFileName = onlyFileName.join('.')
                        this.translations[localeCode] = {
                            AltText: '',
                            FileName: onlyFileName,
                            Description: '',
                            DisplayName: onlyFileName.replace(/[-_+]/g, ' '),
                        }
                    }
                }

                translationList.sort()
                this.translationList = translationList.join('|')
            },

            toggleAllLanguages() {
                const languages = this.languages()
                const translationListCount = this.translationList.split('|').length
                console.log(this.translationList);
                this.translationList = ''
                if (translationListCount !== languages.length) {
                    for (let i = 0; i < languages.length; i++) {
                        const language = languages[i]
                        this.toggleLanguage(language.language.LocaleCode)
                    }
                }
                this.toggleLanguage('zz_ZZ')
            },

            stateFor(localeCode) {
                return this.translationList.replace('::', '_').includes(localeCode)
            },

            isImage(data) {
                return 'Extension' in data && (data.Extension.toLowerCase() == 'jpg' || data.Extension.toLowerCase() == 'png' || data.Extension.toLowerCase() == 'gif' || data.Extension.toLowerCase() == 'jpeg')
            },

            translation(data, key) {
                if ('FileI18ns' in data.row) {
                    let result = this.$root.translation(data.row.FileI18ns, {})

                    if (typeof result === 'object' && Object.keys(result).length <= 0) {
                        result = this.$root.translation(data.row.FileI18ns, {}, 'zz_ZZ')
                    }

                    if (typeof result === 'object' && Object.keys(result).length > 0) {
                        if (key in result) {
                            return result[key]
                        }
                    }
                }

                return '—'
            },

            contentLocale(data) {
                let displayName = '—'

                if ('ContentLocale' in data.row) {
                    this.$root.countries.forEach(country => {
                        country.Languages.forEach(language => {
                            if (language.LocaleCode === data.row.ContentLocale) {
                                displayName = this.$root.translation(language.LanguageI18ns, null, 'de_AT').Name
                            }
                        })
                    })
                }

                return displayName
            },

            /**
             * Uploads a file to the edit path.
             *
             * @param {string} file
             */
            uploadFile() {
                this.$emit('saved')
            },

            copyTranslationValue(name, value) {
                if (confirm(this.$t('Do you want to copy the value of this field to all other languages in this file?'))) {
                    for (const language in this.translations) {
                        if (typeof this.translations[language][name] !== 'undefined') {
                            this.translations[language][name] = value
                        }
                    }
                }
            }
        },
    }
</script>

<template>
    <div style="position: relative">
        <div v-if="sendingData" class="bg-upload__sync"></div>

        <div class="level">
            <div class="level-left">
                <p class="is-size-5">
                    <strong>Mediendetails</strong>
                </p>
            </div>
            <div class="level-right"></div>
        </div>

        <div class="bg-upload__content bg-upload__content--final">
            <div class="columns" style="margin-bottom: 0">
                <div class="column" style="padding-top: 0">
                    <p style="margin-bottom: .5rem; font-size: 1rem; font-weight: 500">{{ $t('Preview') }}</p>
                    <div class="bg-upload__preview-window">
                        <div class="bg-upload__row--preview">
                            <div v-if="Thumbnail !== null" class="bg-upload__row--preview--image">
                                <img :src="Thumbnail" alt="">
                            </div>
                            <p v-else>{{ $root.fileSize(Size) }}</p>
                        </div>
                    </div>
                    <bg-form-upload
                            style="padding: 1rem 0 0"
                            @sync="uploadFile"
                            :upload-to="`file/${Id}?FolderId=${file.response.FolderId}&Collection=${param('for')}&CollectionId=${param('id')}`"
                            :label="$t('Replace file')"
                            :size="12"/>
                </div>

                <div class="column is-two-thirds columns" style="flex-wrap: wrap">
                    <bg-form-input
                            :label="$t('Uploaded as')"
                            :prefill="OriginalName"
                            :size="12"/>
                    <bg-form-select
                            :label="$t('Folder')"
                            name="--random--1"
                            :size="12"
                            :options="folders"
                            :prefill="FolderId"
                            @sync="value => FolderId = value"
                            :option-id="option => option.Id"
                            :option-label="option => $root.translation(option.FolderI18ns, null, $root.Storage.get('settings').locale).Name"/>
                    <bg-form-select
                            :label="$t('Language of content')"
                            name="--random--2"
                            placeholder="Bitte auswählen ..."
                            :size="6"
                            :prefill="ContentLocale"
                            :options="[...[{ Id: 0, CountryI18ns: [{ Id: 0, Locale: 'de_AT', Name: 'International' }], Languages: [{ Id: 0, CountryId: 0, LocaleCode: 'zz_ZZ', LanguageI18ns: [{ Id: 0, Locale: 'de_AT', Name: 'Ohne Sprache' }] }] }], ...$root.countries]"
                            @sync="value => ContentLocale = value"
                            :option-group="option => option.Languages"
                            :option-group-label="option => $root.translation(option.CountryI18ns, null, 'de_AT').Name"
                            :option-id="option => option.LocaleCode"
                            :option-label="option => $root.translation(option.LanguageI18ns, null, 'de_AT').Name"/>
                    <bg-form-toggle
                            :label="$t('File status')"
                            name="--random--3"
                            :prefill="StateId == 1"
                            @sync="value => StateId = value"
                            :toggles="{ 1: $t('Active'), 2: $t('Inactive') }"
                            :size="6"/>
                </div>
            </div>

            <div class="b-tabs">
                <nav class="tabs is-boxed" style="margin: 0 -1.25rem">
                    <ul style="padding: 0 1.25rem">
                        <li :class="{ 'is-active': translationTab === '__overview' }">
                            <a @click.prevent="translationTab = '__overview'"><span>{{ $t('General') }}</span></a>
                        </li>
                        <template v-for="(translation, key) in translationList.replace('::', '_').split('|')">
                            <li :class="{ 'is-active': translationTab === translation }" :key="key" v-if="translation !== 'zz_ZZ'">
                                <a @click.prevent="translationTab = translation"
                                   style="padding-left:.5rem;padding-right:.5rem">
                                    <span>{{ translation.replace('_', '/') }}</span>
                                </a>
                            </li>
                        </template>
                    </ul>
                </nav>

                <section v-if="translationTab === '__overview'" style="maring: 0 -0.75rem; padding: 1rem 0 0">
                    <div class="tab-item columns"
                         :style="{ 'padding-top': '1rem', 'padding-bottom': ($root.hasPermission('Tag', 111) ? '' : '1rem')}">

                        <div class="column is-half columns bg-upload__textarea-hight-fix">
                            <bg-form-input
                                    :textarea="true"
                                    :prefill="Memo"
                                    @sync="value => Memo = value"
                                    name="--random--5"
                                    :label="$t('Internal content description')"
                                    :size="12"/>
                        </div>
                        <div class="column is-half" style="padding-top: 0">
                            <div class="columns" style="margin-bottom: .5rem; font-size: 1rem; font-weight: 500">
                                <p class="column is-half" style="padding-bottom: 0;">{{ $t('Regions for content') }}</p>
                                <p class="column is-half has-text-right" style="padding-bottom: 0;">
                                    <b-checkbox @input="toggleAllLanguages()">
                                        {{ $t('Select all') }}
                                    </b-checkbox>
                                </p>
                            </div>
                            <div class="bg-upload__lang-selection"
                                 :style="{ 'grid-template-rows': 'repeat(' + (((languages().length + 1) / 2) + (languages().length + 1) % 2) + ', 1fr)' }">
                                <template v-for="(language, langKey) in languages()">
                                    <div :key="langKey">
                                        <b-checkbox @input="toggleLanguage(language.language.LocaleCode)"
                                                    :value="stateFor(language.language.LocaleCode)">
                                            <strong>{{ $root.translation(language.country.CountryI18ns, {}, 'de_AT').Name }}</strong><br>
                                            {{ $root.translation(language.language.LanguageI18ns, {}, 'de_AT').Name }}
                                        </b-checkbox>
                                    </div>
                                </template>
                                <div>
                                    <b-checkbox @input="toggleLanguage('zz_ZZ')" :value="stateFor('zz_ZZ')">
                                        <strong>International</strong>
                                    </b-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
<!--                    <div class="tab-item columns" style="padding-bottom: 1rem" v-if="$root.hasPermission('Tag', 111)">-->
<!--                        <bg-form-tags :label="$t('Tags')" :prefill="File2Tags" :size="12" @sync="value => File2Tags = value"/>-->
<!--                    </div>-->
                </section>

                <template v-for="(translation, key) in translationList.replace('::', '_').split('|')">
                    <section :key="key" v-if="translationTab === translation">
                        <div class="tab-item columns" style="padding-top: 1rem; padding-bottom: 1rem; flex-wrap: wrap">
                            <bg-form-input
                                    :label="$t('Display name')"
                                    :name="'name--' + translation + '--a'"
                                    :readonly="translation === 'zz_ZZ'"
                                    :prefill="translations[translation].DisplayName"
                                    @sync="value => translations[translation]['DisplayName'] = value"
                                    :size="4">
                                <button class="button is-input-copy" @click.prevent="copyTranslationValue('DisplayName', translations[translation]['DisplayName'])">
                                    <b-icon icon="content-copy" custom-size="default"></b-icon>
                                </button>
                            </bg-form-input>
                            <bg-form-input
                                    :label="$t('Filename')"
                                    :name="'name--' + translation + '--b'"
                                    :readonly="translation === 'zz_ZZ'"
                                    :prefill="translations[translation].FileName"
                                    @sync="value => translations[translation]['FileName'] = value"
                                    :size="4">
                                <button class="button is-input-copy" @click.prevent="copyTranslationValue('FileName', translations[translation]['FileName'])">
                                    <b-icon icon="content-copy" custom-size="default"></b-icon>
                                </button>
                            </bg-form-input>
                            <bg-form-input
                                    :label="$t('ALT-text')"
                                    :name="'name--' + translation + '--c'"
                                    :readonly="translation === 'zz_ZZ'"
                                    :prefill="translations[translation].AltText"
                                    @sync="value => translations[translation]['AltText'] = value"
                                    :size="4">
                                <button class="button is-input-copy" @click.prevent="copyTranslationValue('AltText', translations[translation]['AltText'])">
                                    <b-icon icon="content-copy" custom-size="default"></b-icon>
                                </button>
                            </bg-form-input>
                            <bg-form-input
                                    :label="$t('Description')"
                                    :textarea="true"
                                    :name="'name--' + translation + '--d'"
                                    :readonly="translation === 'zz_ZZ'"
                                    :prefill="translations[translation].Description"
                                    @sync="value => translations[translation]['Description'] = value"
                                    :size="9">
                                <button class="button is-input-copy" @click.prevent="copyTranslationValue('Description', translations[translation]['Description'])">
                                    <b-icon icon="content-copy" custom-size="default"></b-icon>
                                </button>
                            </bg-form-input>
                            <bg-form-input
                                    :label="$t('Sorting')"
                                    :name="'name--' + translation + '--e'"
                                    :readonly="translation === 'zz_ZZ'"
                                    :prefill="translations[translation].Sort"
                                    @sync="value => translations[translation]['Sort'] = value"
                                    :size="3">
                                <button class="button is-input-copy" @click.prevent="copyTranslationValue('Sort', translations[translation]['Sort'])">
                                    <b-icon icon="content-copy" custom-size="default"></b-icon>
                                </button>
                            </bg-form-input>
                        </div>
                    </section>
                </template>

                <div>
                    <div class="level">
                        <div class="level-left"></div>
                        <div class="level-right">
                            <button @click="saveValues" class="button is-primary" v-html="$t('Save file information')"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
