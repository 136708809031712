<script>
    export default {
        props: {
            label: {
                type: String,
                default: 'auto',
            },
            name: {
                type: String,
            },
            prefill: {
                type: Boolean,
                default: false,
            },
            toggles: {
                type: [Array, Object],
            },
            size: {
                type: Number,
                default: 3,
            },
        },

        data() {
            return {
                labelDisplay: '',
                value: false,
                optionsIntern: this.options,
            }
        },

        watch: {
            value(value) {
                this.$emit('sync', this.getValue(value))
                this.labelDisplay = this.getLabel(value)
                this.$children[0].updateValue(value)
            },
            options(value) {
                this.optionsIntern = value
            },
            prefill(value) {
                this.value = value
            },
        },

        mounted() {
            this.value = this.prefill
            this.labelDisplay = this.getLabel(this.prefill)
        },

        methods: {
            appendTo(content) {
                if (this.value !== null) {
                    if (typeof this.toggles === 'object') {
                        if (Array.isArray(this.toggles)) {
                            content[this.name] = this.toggles[this.value ? 0 : 1]
                        } else {
                            content[this.name] = Object.keys(this.toggles)[this.value ? 0 : 1]
                        }
                    } else {
                        content[this.name] = this.value
                    }
                }
                return content
            },

            getLabel(value) {
                if (typeof this.toggles === 'object' && !Array.isArray(this.toggles)) {
                    return Object.values(this.toggles)[value ? 0 : 1]
                }

                return ''
            },

            getValue(value) {
                if (typeof this.toggles === 'object') {
                    if (!Array.isArray(this.toggles)) {
                        return Object.keys(this.toggles)[value ? 0 : 1]
                    } else {
                        return this.toggles[value ? 0 : 1]
                    }
                }

                return ''
            },
        },
    }
</script>

<template>
    <bg-form-wrapper :label="label" :name="name" :size="size" :prefill="prefill">
        <div class="field">
            <b-switch v-model="value" type="is-success" size="is-medium">
                <span style="font-size: 16px">{{ labelDisplay }}</span>
            </b-switch>
        </div>
    </bg-form-wrapper>
</template>
