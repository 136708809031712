<script>
    export default {
        props: {
            filter: {
                type: Object,
                default() {
                    return {}
                },
            },
            label: {
                type: String,
                default: '',
            },
        },
    }
</script>

<template>
    <bg-table-collection view="re-details?for=Group&amp;id=${Id}"
                         :label="label"
                         path="group"
                         object="Group"
                         :filter="filter"
                         detail-view="Product"
                         :main-view="true"
                         :state-view="true"/>
</template>
